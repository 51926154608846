import React from 'react';
import { Link, graphql } from 'gatsby';
import { Breadcrumb, Typography, Card, Row, Col } from 'antd';
import { Category } from '../types/Category';
import { MdxArticle } from '../types/Article';
import { SiteMetadata } from '../types/SiteMetadata';
import ArticleList from '../components/ArticleList';
import { SEO } from '../components/SEO';

export const query = graphql`
  query ($categoryId: String!) {
    category(id: { eq: $categoryId }) {
      name
      description
      url
      slug
    }
    site {
      siteMetadata {
        siteUrl
        title
        description
      }
    }
    allMdx {
      edges {
        node {
          id
          frontmatter {
            title
            description
            category
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

const { Title } = Typography;

export default function ({
  data
}: {
  data: {
    site: { siteMetadata: SiteMetadata };
    category: Category;
    allMdx: { edges: { node: MdxArticle }[] };
  };
}) {
  const {
    category,
    site: { siteMetadata },
    allMdx
  } = data;
  const articles = allMdx.edges
    .filter((edge) => edge.node.frontmatter.category == category.slug)
    .map((edge) => edge.node);

  return (
    <>
      <Row>
        <SEO
          title={category.name}
          description={category.description}
          siteUrl={siteMetadata.siteUrl}
          pathname={`/categories/${category.slug}`}
        />
        <Col xs={24} span={24}>
          <Card>
            <Breadcrumb aria-label="Navigation">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{category.name}</Breadcrumb.Item>
            </Breadcrumb>
            <Title level={3}>{category.name}</Title>
            <Title level={4}>{category.description}</Title>
            <ArticleList articles={articles} />
          </Card>
        </Col>
      </Row>
    </>
  );
}
