import React from "react";
import { Link } from "gatsby";
import { List } from "antd";
import { MdxArticle } from "../types/Article";

const ArticleList: React.FC<{ articles: MdxArticle[] }> = ({ articles }) => {
  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={articles}
        renderItem={article => (
          <List.Item>
            <List.Item.Meta
              title={<Link to={article.fields.slug}> {article.frontmatter.title}{" "}</Link>}
              description={article.frontmatter.description}
            />
          </List.Item>
        )}
      />
    </>
  );
};

export default ArticleList;
